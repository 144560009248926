<template>
	<div class="ybd-view">
		<ucenterHeader ></ucenterHeader>
		<div class="container">
			<ucenterLeftNav></ucenterLeftNav>
			<div class="main">
				<div class="u-rightbar">
					<div class="module-title">
						<div><i></i><span>消息中心</span></div>
						<div>
						</div>
					</div>
					<div class="notices" v-if="list.length > 0">
						<ul>
							<li v-for="item in list" :key="item.id">
								<div class="item-date">
									<div>{{item.createDate |monthDay}}</div>
									<p>{{item.createDate |year}}</p>
								</div>
								<dl class="item-cont">
									<dt><a href="javaScript: void(0);" @click="showNotice(item.id)">{{item.name}}</a></dt>
									<dd>{{item.content}}</dd>
								</dl>
							</li>
						</ul>
						<el-pagination @size-change="pageSizeChange" @current-change="pageCurrentChange" :current-page="listParams.pageNo" :page-sizes="[10, 20, 30, 50]" :page-size="listParams.pageSize" layout="total, prev, pager, next, jumper" :total="count" prev-text="上一页" next-text="下一页" background></el-pagination>
					</div>
					<noData v-else text="暂时还没有数据哦！"></noData>
				</div>
				<el-dialog title="消息详情" :visible.sync="dialogVisible" width="600px">
					<div class="notice-body">
						<h4 class="notice-title">{{detail.name}}</h4>
						<p class="notice-time">{{detail.createDate}}</p>
						<div class="notice-content">{{detail.content}}</div>
					</div>
					<div slot="footer" class="dialog-footer">
						<el-button type="primary" @click="cancel">确定</el-button>
					</div>
				</el-dialog>
			</div>
		</div>
	</div>
</template>
<script>
import { get,post } from "/src/services/ajax_ucenter.js";
import ucenterHeader from "@/components/ucenterHeader.vue";
import ucenterLeftNav from "@/components/ucenterLeftNav.vue";
import noData from "@/components/noData";
export default {
	components: {
		ucenterHeader,
		ucenterLeftNav,
		noData,
	},
	data(){
		return {
			list:[],
			listParams:{
				pageNo:1,
				pageSize:20
			},
			count: 0,
			dialogVisible:false,
			detail:{},
		}
	},
	created(){
		this.getList();
	},
	filters:{
		year(val){
			let arr = val.split('-');
			return arr[0]
		},
		monthDay(val){
			let arr = val.split('-');
			return arr[1]+'-'+ arr[2]
		}
	},
	methods: {
		// 获取模板列表
		getList(){
			const _this = this;
			get('/formadmin/ucenter_msg_list.jhtml',_this.listParams).then(res => {
				if(res.code == 200){
					_this.count = res.data.count;
					_this.list = res.data.date;
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		pageCurrentChange(parm){
			this.listParams.pageNo = parm;
			this.getList();
		},
		pageSizeChange(parm){
			this.listParams.pageSize = parm;
			this.getList();
		},
		// 查看
		showNotice(id){
			const _this = this;
			post('/formadmin/ucenter_msg_detail.jhtml',{id}).then(res => {
				if(res.code == 200){
					_this.detail = res.data;
					_this.dialogVisible = true;
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		// 取消
		cancel(){
			const _this = this;
			_this.dialogVisible = false;
		},
	}
};
</script>
<style lang="less" scoped>
.main {
	display: flex;
	flex-direction: column;
	padding: 20px;
	overflow-y: auto;
	.u-rightbar {
		flex: 1;
		display: flex;
		flex-direction: column;
		padding: 20px 30px;
		background: #fff;
		.module-title{
			font-size: 16px;
			line-height: 36px;
			color:#333;
			margin-bottom: 10px;
			padding:0;
			border-bottom:none; 
			display: flex;
			justify-content: space-between;
			i{
				width: 4px;
				height:16px;
				background: var(--theme-color);
				margin-right: 10px;
				display: inline-block;
				vertical-align:-2px;
			}
		}
		.notices{
			ul{
				border-top: 1px solid #eee;
				li{
					padding: 20px;
					border-bottom: 1px solid #eee;
					overflow: hidden;
					.item-date{
						width:85px;
						height: 85px;
						float: left;
						background: #f8f8f8;
						border-radius: 4px;
						overflow: hidden;
						text-align: center;
						div{
							font-size: 22px;
							line-height: 30px;
							padding: 17px 0 13px;
						}
						p{
							height: 28px;
							line-height: 24px;
							color:#999;
							background: #efefef;
						}
					}
					.item-cont{
						margin-left: 107px;
						dt{
							font-size: 16px;
							line-height: 30px;
							white-space: nowrap;
							text-overflow: ellipsis;
							overflow: hidden;
						}
						dd{
							font-size: 14px;
							line-height:24px;
							height:48px;
							overflow:hidden;
							margin-top: 7px;
							color:#999;
						}
					}
				}
			}
		} 
	}
	::v-deep .el-dialog{
		.el-dialog__header{
			border-bottom:1px solid #DCDFE6;
		}
		.el-dialog__body{
			height: 400px;
			overflow-y: auto;
			.notice-body{
				height: 100%;
				overflow: auto;
				padding:0;
				box-sizing: border-box;
				.notice-title{
					font-size: 20px;
					font-weight: bold;
					line-height: 1.8;
					text-align: center;
				}
				.notice-time{
					color:#999;
					text-align: center;
					line-height: 1.5;
					font-size: 13px;
				}
			}
		}
	} 
	::v-deep .el-pagination{
		padding: 20px 10px 10px;
		font-weight: normal;
		text-align: right;
		.el-pagination__total,
		.el-pagination__sizes{
			float: left;
			.el-input{
				.el-input__inner{
					&:hover{
						border-color: var(--theme-color);
					}
				}
			}
		}
		.el-pager{
			li:not(.disabled){
				&:hover{
					color: var(--theme-color);
				}
			}
			li:not(.disabled).active{
				background-color: var(--theme-color);
				&:hover{
					color: #fff;
				}
			}
		}
		.btn-prev,
		.btn-next{
			font-size: 12px;
			padding: 0 5px;
		}
	}	
		
}
</style>